import {
  CloudDownloadOutlined,
  DownOutlined,
  UpOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
const UploadBox = ({ List, setList }) => {
  const [open, setOpen] = useState(true);

  window.onbeforeunload = function () {
    if (List.length > 0)
      return "Data will be lost if you leave the page, are you sure?";
  };

  return (
    <div className="Upload-box d-flex flex-column gap-2 pb-3">
      <div className="header mb-2 px-3 d-flex flex-row gap-3 ">
        <div className="col-10">Uploading {List.length} items</div>
        <div dir="rtl">
          {open ? (
            <DownOutlined
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : (
            <UpOutlined
              onClick={() => {
                setOpen(!open);
              }}
            />
          )}
        </div>
      </div>
      {open &&
        List.map((item, i) => {
          return (
            <>
              {" "}
              <div className="d-flex flex-row gap-3 py-1 px-3">
                <div className="col-9 d-flex flex-row gap-3">
                  {item.loading ? <LoadingOutlined /> : <CheckCircleOutlined />}
                  <span>{item.name}</span>
                </div>
                <CloudDownloadOutlined
                  onClick={() => {
                    let link = document.createElement("a");
                    link.href = item.url;
                    link.download = item.date + ".mp3";
                    link.click();
                    link.remove();
                  }}
                />
                <DeleteOutlined
                  onClick={() => {
                    var temp = List.filter((i) => {
                      return i != item;
                    });
                    setList(temp);
                  }}
                />
              </div>
              {i != List.length - 1 && <div className="h-line" />}
            </>
          );
        })}
    </div>
  );
};
export default UploadBox;
