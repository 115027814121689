import React, { useState, useEffect } from "react";
import { connect, io } from "socket.io-client";
import Cookies from "js-cookie";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";
import { Modal, Select } from "antd";
const RealTimeAudioRecorder = (
  isActive,
  section,
  numberOfChannels,
  setIsActive,
  setuploadOpen,
  setTranscip,
  transcript,
  setAudioStrim,
  voiceId,
  desktopStream,
  voiceStream,
  isStop,
  setIsStop,
  setVoiceId,
  setIsOnlyShow
) => {
  const [socket, setSocket] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  let stream;
  const authorizationKey = Cookies.get("access_token");
  const mergeAudioStreams = (
    desktopStream,
    voiceStream,
    singleChannel = true
  ) => {
    const context = new AudioContext();

    let merger = null;
    if (!singleChannel) {
      merger = context.createChannelMerger(2);
      merger.channelCountMode = "explicit";
    }

    const destination = context.createMediaStreamDestination();

    // osc1.connect( merger, 0, 0 );
    // osc2.connect( merger, 0, 1 );

    let hasDesktop = false;
    let hasVoice = false;

    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain);
      // desktopGain.connect(osc1); // Connect to left channel

      if (!singleChannel && merger) {
        desktopGain.connect(merger, 0, 1);
      } else {
        desktopGain.connect(destination);
      }

      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain);
      // voiceGain.connect(osc2); // Connect to right channel

      if (!singleChannel && merger) {
        voiceGain.connect(merger, 0, 0);
      } else {
        voiceGain.connect(destination);
      }

      hasVoice = true;
    }

    if (!singleChannel && merger) {
      merger.connect(destination);
    }

    return destination.stream;
  };
  useEffect(() => {
    if (isStop) {
      if (socket) {
        stopStreaming();
      }
      setIsStop(false);
    }
  }, [isStop]);
  const startRecording = async () => {
    setAudioStrim(true);
    setIsRecording(true);
    let mysocket;
    if (socket) {
      mysocket = socket;
    } else {
      if (!mysocket) {
        if (voiceId) {
          mysocket = await io(
            "wss://api-stg.allbrainsclinic.com/real-transcription",
            {
              query: {
                sectionId: section.id,
                voiceId: voiceId,
                numberOfChannels: numberOfChannels,
              },
              extraHeaders: { Authorization: authorizationKey },
            }
          );
        } else
          mysocket = await io(
            "wss://api-stg.allbrainsclinic.com/real-transcription",
            {
              query: {
                sectionId: section.id,
                numberOfChannels: numberOfChannels,
              },
              extraHeaders: { Authorization: authorizationKey },
            }
          );
      }
      setSocket(mysocket);
    }

    let tracks = mergeAudioStreams(
      desktopStream,
      voiceStream,
      numberOfChannels == 1
    );

    stream = new MediaStream(tracks);
    let recorder = new RecordRTC(stream, {
      type: "audio",
      mimeType: "audio/wav",
      recorderType: StereoAudioRecorder,
      desiredSampRate: 16000,
      numberOfAudioChannels: numberOfChannels,
      bufferSize: 256,
      timeSlice: 1000 / numberOfChannels,
      ondataavailable: async function (blob) {
        mysocket.emit(
          "voice_buffers",
          new Uint8Array(await blob.arrayBuffer())
        );
      },
    });

    recorder.startRecording();
    mysocket.on("transcription", (data) => {
      let mytranscript = document.getElementById(`transcript_${data.id}`);
      if (!mytranscript) {
        var div = document.createElement("div");
        div.id = `transcript_${data.id}`;
        var content = document.createElement("p");
        content.className = "transcript_title";
        content.innerHTML =
          data.role +
          ": " +
          `${
            data.startTime > 3600
              ? Math.floor(data.startTime / 3600) +
                ":" +
                Math.floor((data.startTime % 3600) / 60)
                  .toString()
                  .padStart(2, "0") +
                ":" +
                (data.startTime % 60).toString().padStart(2, "0")
              : Math.floor(data.startTime / 60) +
                ":" +
                (data.startTime % 60).toString().padStart(2, "0")
          }`;
        div.appendChild(content);
        var content2 = document.createElement("p");
        content2.className = "transcript_content";
        content2.innerHTML = data.content;
        div.appendChild(content2);
        document.getElementById("transcriptionContainer").appendChild(div);
      } else {
        mytranscript.innerHTML = "";
        var content = document.createElement("p");
        content.className = "transcript_title";
        content.innerHTML =
          data.role +
          ": " +
          `${
            data.startTime > 3600
              ? Math.floor(data.startTime / 3600) +
                ":" +
                Math.floor((data.startTime % 3600) / 60)
                  .toString()
                  .padStart(2, "0") +
                ":" +
                (data.startTime % 60).toString().padStart(2, "0")
              : Math.floor(data.startTime / 60) +
                ":" +
                (data.startTime % 60).toString().padStart(2, "0")
          }`;
        mytranscript.appendChild(content);
        var content2 = document.createElement("p");
        content2.className = "transcript_content";
        content2.innerHTML = data.content;
        mytranscript.appendChild(content2);
      }
      setTranscip(document.getElementById("transcriptionContainer").innerHTML);
    });

    mysocket.on("disconnected", (data) => {
      // if (recorder) {
      //   recorder.stop();
      // }
      mysocket.disconnect();
      stopStreaming();
      alert(data);
    });
  };

  function stopStreaming() {
    pauseMic();
    setAudioStrim(false);
    setIsRecording(false);
    setVoiceId(null);
    setSocket(null);
  }

  function pauseMic() {
    if (socket) {
      socket.emit("disconnected", true);
      socket.disconnect();
    }
  }
  return (
    <>
      {isRecording && isActive == section.id ? (
        <div
          className="d-flex px-2"
          style={{
            justifyContent: "space-between",
            borderBottom: "1px rgba(242, 242, 242, 1)",
          }}
        >
          <div
            className="d-flex"
            style={{ alignItems: "center", paddingLeft: "30px" }}
          >
            <div class="loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <button
            onClick={async () => {
              // setcallUseEffect(true);
              setIsStop(true);
              // stopStreaming();
            }}
            style={{ border: "none", backgroundColor: "white" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="red"
              class="bi bi-stop-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z" />
            </svg>
          </button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            height: "37px",
          }}
        >
          <div
            id={"startRecording" + section.id}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 10px",
              marginLeft: "35px",
            }}
            onClick={async () => {
              setIsOnlyShow(false);
              if (!voiceId) {
                setTranscip("");
              }
              if (!voiceStream) {
                Modal.error({ title: "Please set your microphon." });
              } else {
                setIsActive(section.id);
                startRecording();
              }
            }}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5" cy="17.5" r="17.5" fill="#FF0000" />
              <path
                d="M17.5 7.69995C15.843 7.69995 14.5 9.03588 14.5 10.6842V16.6526C14.5 18.3009 15.843 19.6368 17.5 19.6368C19.157 19.6368 20.5 18.3009 20.5 16.6526V10.6842C20.5 9.03588 19.157 7.69995 17.5 7.69995ZM10.5 16.6526C10.5 20.1593 13.1094 23.052 16.5 23.5361V26.6H18.5V23.5361C21.8906 23.052 24.5 20.1593 24.5 16.6526H22.5C22.5 19.3991 20.261 21.6263 17.5 21.6263C14.739 21.6263 12.5 19.3991 12.5 16.6526H10.5Z"
                fill="white"
              />
            </svg>
            <strong style={{ color: "#FF0000", marginLeft: "5px" }}>
              Start
            </strong>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 10px",
            }}
            onClick={() => setuploadOpen(true)}
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5" cy="17.5" r="17.5" fill="#2A2A2A" />
              <path
                d="M20.1266 24.7309H11.5286C10.3676 24.7309 9.42416 23.7861 9.42416 22.6252L9.42432 11.5285C9.42432 10.3676 10.3676 9.42282 11.5287 9.42282H22.6275C23.7884 9.42282 24.7325 10.3676 24.7325 11.5285V17.307C24.7325 17.598 24.9684 17.8333 25.2581 17.8333C25.5491 17.8333 25.7844 17.5974 25.7844 17.307L25.7842 11.5285C25.7842 9.78736 24.3678 8.36963 22.6266 8.36963H11.5287C9.78752 8.36963 8.37109 9.78736 8.37109 11.5285V22.6256C8.37109 24.3668 9.78752 25.7845 11.5287 25.7845H20.1267C20.4177 25.7845 20.653 25.5486 20.653 25.2582C20.653 24.9677 20.4179 24.7307 20.1267 24.7307L20.1266 24.7309Z"
                fill="white"
              />
              <path
                d="M13.5636 15.0291V19.446C13.4572 19.4305 13.3486 19.4197 13.2361 19.4197C12.2112 19.4197 11.3789 20.0713 11.3789 20.8766C11.3789 21.6818 12.2103 22.3348 13.2361 22.3348C14.2622 22.3348 15.0952 21.6818 15.0952 20.8766L15.0953 15.5344L20.0614 14.4172V18.2156C19.955 18.2014 19.8471 18.1892 19.7354 18.1892C18.7092 18.1892 17.8769 18.8422 17.8769 19.6474C17.8769 20.4527 18.7084 21.1043 19.7354 21.1043C20.761 21.1043 21.5938 20.4527 21.5938 19.6474L21.5935 14.0724V11.8191L13.5636 13.6262L13.5636 15.0291Z"
                fill="white"
              />
              <path
                d="M21.9112 23.5483H23.8101V27.2887C23.8101 27.6034 24.0689 27.8616 24.3842 27.8616H26.1334C26.4495 27.8616 26.7076 27.6035 26.7076 27.2887V23.5483H28.6065C28.9219 23.5483 29.0102 23.3549 28.8026 23.1163L25.637 19.4999C25.4294 19.2627 25.0898 19.2627 24.8822 19.4999L21.7166 23.1163C21.5081 23.3542 21.5965 23.5483 21.9112 23.5483Z"
                fill="white"
              />
            </svg>
            <strong style={{ color: "#2A2A2A", marginLeft: "5px" }}>
              Upload
            </strong>
          </div>
        </div>
      )}
    </>
  );
};

export default RealTimeAudioRecorder;
